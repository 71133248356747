/*    
<summary>
  This functional component "Add Parking Lot" provides form to add new Parking Lot or update existing Parking Lot.
  Developer:Mohammad Saquib Khan, Created Date:15-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Form, Formik, Field, FieldArray } from "formik";
import "./add-edit-parking-lot-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { IOption } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import {
  ParkingLotInputType,
  ParkingLotOutputType,
  permissionTypeOptions,
} from "../../../constants/options.constant";
import { useEffect, useState } from "react";
import IAddTenant from "../../../models/forms/IAddUpdateTenant";
import {
  tenantDetailValidateSchema,
  AddUserValidateSchema,
  EditUserValidateSchema,
  AddParkingLotLinkBaseValidateSchema,
  AddParkingLotDetailsValidateSchema,
  AddParkingLotCategoryDetailsValidateSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import categoryEnum from "../../../constants/enums/category-enum";
import { formatMessage } from "../../../translations/format-message";
import React from "react";

interface IProps {
  id: number;
  branchOptions: IOption[];
  linkBaseInputOptions: IOption[];
  linkBaseOutputOptions: IOption[];
  parkingLotStatusOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  isLoading: boolean;
  isDataLoading: boolean;
  initialValues: any;
}

const AddUpdateParkingLotForm = (props: IProps) => {
  const { initialValues, submitHandler, isLoading, onModalClosed } = props;

  const [data, setData] = useState({ ...initialValues });
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setData({ ...initialValues });
  }, [initialValues]);

  const handleNextStep = (newData: any, final: boolean = false) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    if (final) {
      let tempValues: any = { ...newData };
      submitHandler(tempValues);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const StepOne = (props1: any) => {
    const handleSubmit = (values: any) => {
      props1.next(values);
    };
    return (
      <Formik
        initialValues={props1.data}
        onSubmit={handleSubmit}
        validationSchema={AddParkingLotDetailsValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, isValid }) => (
          <Form id="formBody">
            {props.isDataLoading ||
              (isLoading && (
                <FormLoader loading={props.isDataLoading || isLoading} />
              ))}
            {/* {props.id === -1 && */}
            <div className="pt-3">
              <div className="progress step-progress">
                <div className="progress-bar" role="progressbar"></div>
              </div>
              <div className="step-container d-flex justify-content-between">
                <div className="step-circle active">
                  1{" "}
                  <span className="step-detail">
                    <FormattedMessage id="parking_details" />
                  </span>
                </div>
                <div className="step-circle">
                  2{" "}
                  <span className="step-detail">
                    <FormattedMessage id="linkbase_details" />
                  </span>
                </div>
                <div className="step-circle">
                  3{" "}
                  <span className="step-detail">
                    <FormattedMessage id="category_details" />
                  </span>
                </div>
              </div>
            </div>
            {/* } */}

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-sm-6 col-form-label"
                    required={true}
                    label="parking_lot_name"
                  />
                  <Field
                    data-testid="PlName"
                    className="form-control"
                    name="PlName"
                    placeholder="parking_lot_name"
                    type="text"
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={true}
                    label="parking_lot_id"
                  />
                  <Field
                    data-testid="ParkingLotId"
                    className="form-control"
                    name="ParkingLotId"
                    placeholder="parking_lot_id"
                    type="text"
                    disabled={props.id > 0 ? true : false}
                    validcharacter={["@", "#", "$", "-", "_", "."]}
                    as={FormikFormInput}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={true}
                    label="select_branches"
                  />
                  <Field
                    name="BranchId"
                    className="form-control"
                    as={FormikFormSelect}
                    options={props.branchOptions}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style col-sm-12 col-form-label"
                    required={true}
                    label="select_status"
                  />
                  <Field
                    name="CurrentStatus"
                    className="form-control"
                    as={FormikFormSelect}
                    options={props.parkingLotStatusOptions}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group formSet">
                  <Label
                    className="label-style"
                    required={false}
                    label="comment"
                  />
                  <Field
                    rows={3}
                    id="Comment"
                    className="form-control"
                    name="Comment"
                    placeholder="comment"
                    type="text"
                    as={FormikFormTextArea}
                  />
                </div>
              </div>
            </div>
            <div className="pb-3 mt-4 d-flex justify-content-center">
              <LoaderButton
                type="submit"
                id="SaveParkingLot"
                isLoading={props.isLoading}
                text="next"
              />
              {props.id > 0 && (
                <LoaderButton
                  type={isValid ? "submit" : "button"}
                  id="SaveTenant"
                  onClick={
                    isValid
                      ? () => {
                          props1.next(values, true);
                        }
                      : undefined
                  }
                  isLoading={props.isLoading}
                  text="update_parking"
                  className="ms-3"
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const StepTwo = (props2: any) => {
    const handleSubmit = (values: any) => {
      props2.next(values, false); // Change final to false to move to the next step
    };

    return (
      <Formik
        initialValues={props2.data}
        onSubmit={handleSubmit}
        validationSchema={AddParkingLotLinkBaseValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form id="formBody">
              {props.isDataLoading ||
                (isLoading && (
                  <FormLoader loading={props.isDataLoading || isLoading} />
                ))}
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div
                    className={`progress-bar ${
                      props.id === -1 ? "w-66" : "w-100"
                    }`}
                    role="progressbar"
                  ></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle completed">
                    1{" "}
                    <span className="step-detail">
                      <FormattedMessage id="parking_details" />
                    </span>
                  </div>
                  <div className="step-circle active">
                    2{" "}
                    <span className="step-detail">
                      <FormattedMessage id="linkbase_details" />
                    </span>
                  </div>
                  <div className="step-circle">
                    3{" "}
                    <span className="step-detail">
                      <FormattedMessage id="category_details" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style col-sm-12 col-form-label"
                      required={true}
                      label="input_type"
                    />
                    <Field
                      name="ParkingLotInputTypeEnum"
                      className="form-control"
                      as={FormikFormSelect}
                      disabled={props.id > 0 ? true : false}
                      options={props.linkBaseInputOptions}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style col-sm-12 col-form-label"
                      required={true}
                      label="output_type"
                    />
                    <Field
                      name="ParkingLotOutputTypeEnum"
                      className="form-control"
                      as={FormikFormSelect}
                      disabled={props.id > 0 ? true : false}
                      options={props.linkBaseOutputOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-3 mt-4 d-flex justify-content-center">
                <Button
                  data-testid="back"
                  variant="light-secondary"
                  onClick={() => {
                    props2.prev(values);
                  }}
                  disabled={props.isLoading}
                  className="mr-3"
                >
                  <FormattedMessage id="back" />
                </Button>
                <LoaderButton
                  type="submit"
                  id="SaveParkingLot"
                  className="ms-2"
                  isLoading={props.isLoading}
                  text="next"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const StepThree = (props3: any) => {
    const handleSubmit = (values: any) => {
      props3.next(values, true); // Set final to true to submit the form
    };

    return (
      <Formik
        initialValues={props3.data}
        onSubmit={handleSubmit}
        validationSchema={AddParkingLotCategoryDetailsValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form id="formBody">
              {props.isDataLoading ||
                (isLoading && (
                  <FormLoader loading={props.isDataLoading || isLoading} />
                ))}
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div
                    className={`progress-bar ${
                      props.id === -1 ? "w-100" : "w-100"
                    }`}
                    role="progressbar"
                  ></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle completed">
                    1{" "}
                    <span className="step-detail">
                      <FormattedMessage id="parking_details" />
                    </span>
                  </div>
                  <div className="step-circle completed">
                    2{" "}
                    <span className="step-detail">
                      <FormattedMessage id="linkbase_details" />
                    </span>
                  </div>
                  <div className="step-circle active">
                    3{" "}
                    <span className="step-detail">
                      <FormattedMessage id="category_details" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="tableDesign border">
                <table className="table table-bordered border camera-table">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="slot_category" />
                      </th>
                      <th>
                        <FormattedMessage id="description" />
                      </th>
                    </tr>
                  </thead>
                  <FieldArray name="ParkingLotsCategories">
                    {({ insert, remove, push }) => (
                      <tbody>
                        {props.initialValues.ParkingLotsCategories?.map(
                          (category: any, index: number) =>
                            category.Category !== 5 && (
                              <tr key={index}>
                                <td>
                                  <Label
                                    className="label-style"
                                    label={categoryEnum[category.Category]}
                                    required={true}
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`ParkingLotsCategories.${index}`}
                                  >
                                    {({ field }: any) => (
                                      <React.Fragment>
                                        <Field
                                          data-testid="enable_disable"
                                          className="form-control"
                                          name={`ParkingLotsCategories.${index}.Description`}
                                          type="text"
                                          as={FormikFormInput}
                                        />
                                      </React.Fragment>
                                    )}
                                  </Field>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    )}
                  </FieldArray>
                </table>
              </div>

              <div className="pb-3 mt-4 d-flex justify-content-center">
                <Button
                  data-testid="back"
                  variant="light-secondary"
                  onClick={() => {
                    props3.prev(values);
                  }}
                  disabled={props.isLoading}
                  className="mr-3"
                >
                  <FormattedMessage id="back" />
                </Button>
                <LoaderButton
                  type="submit"
                  id="SaveParkingLot"
                  className="ms-2"
                  isLoading={props.isLoading}
                  text={props.id == -1 ? "submit" : "update_parking"}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const steps = [
    <StepOne
      next={handleNextStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
    <StepTwo
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
    <StepThree
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
  ];

  return (
    <div>
      {isLoading && <FormLoader loading={isLoading} />}
      {steps[currentStep]}
    </div>
  );
};

export default AddUpdateParkingLotForm;
