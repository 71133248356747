/*    
<summary>
   This file defines axios interceptors have request handler and error handler. 
</summary>
*/
import axios, { AxiosResponse } from "axios";
import { errorMessage } from "../../constants/error-constants";
import { AuthStore } from "../stores/auth-store";
import { clearLocalStorageFields } from "../../constants/constants";
import RoutesConstants from "../../constants/routes.constant";
import config from "../../helpers/config-helper";
import { getRefreshToken, getToken } from "../../helpers/localstorage-helper";
import URLConstants from "../../constants/url-constants";

const appConfig = config();
export const baseURL = appConfig.REACT_APP_API_URL;
export const baseAPI = axios.create({ baseURL });

const requestHandler = (request: any) => {
  const token = new AuthStore().getToken;
  //don't send authorization in headers for these 3 requests
  if (
    request.url === `${URLConstants.RefreshToken}` ||
    request.url === `${URLConstants.Authenticate}`
  )
    return request;
  request.headers["authorization"] = "Bearer " + token;
  return request;
};

const refreshTokenHandler = async (originalRequest: any) => {
  const authStore = new AuthStore();
  if (!originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const rs: any = await baseAPI.post(URLConstants.RefreshToken, {
        AccessToken: getToken(),
      });
      authStore.updateToken(rs.data.Data);
      return baseAPI(originalRequest);
    } catch (_error) {
      return Promise.reject(_error);
    }
  }
};

const errorHandler = async (error: any) => {
  
  let message: string = errorMessage.ErrorOccured;
  const originalRequest: any = error.config;
  if (error.response) {
    if (error.response.status === 401) {
      const err = error.response.data?.Message
        ? error.response.data?.Message
        : error.response.data;
      
      switch (err) {
        case errorMessage.TokenExpired:
          return await refreshTokenHandler(originalRequest);
        case errorMessage.RefreshTokenExpired:
        case errorMessage.RefreshTokenNotInDatabase:
          message = errorMessage.RefreshTokenExpired;
          // message = ErrorMessage.Unauthorized;
          setTimeout(() => {
            const lang: any = localStorage.getItem("language");
            localStorage.clear();
            localStorage.setItem("language", lang === "jp" ? "jp" : "en");
            window.location.href = RoutesConstants.Login;
          }, 3000);
          return Promise.reject(err);
        case errorMessage.InvalidEmailOrPassword:
          message = error.response.data.Message;
          break;
        case errorMessage.Unauthorized:
          message = error.response.data.Message;
          localStorage.clear();
          window.location.href = RoutesConstants.Login;
          break;
        case errorMessage.YourAccountInactive:
          message = error.response.data.Message;
          break;
        default:
          // localStorage.clear();
          // window.location.href = RoutesConstants.Login
          return Promise.reject(message);
      }
    } else if (error.response.status === 403) {
      message = errorMessage.UnauthorizedAccess;
    } else if (
      error.response.data &&
      error.response.data.Message &&
      error.response.data.Message !== null &&
      error.response.data.Message !== undefined
    ) {
      message = error.response.data.Message;
    }
  }
  return Promise.reject(message);
};

const successHandler = (response: AxiosResponse): AxiosResponse => {
  return response;
};

baseAPI.interceptors.request.use((request) => requestHandler(request));
baseAPI.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
