/*    
<summary>
   This class component is all about Managing Integrator data functionality.
   Developer: Urvashi, Created Date:25-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { action, computed, makeObservable, observable } from "mobx";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { ICommonState } from "../../models/state/ICommonState";
import URLConstants from "../../constants/url-constants";
import * as baseService from "../service/base-service";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import { initialState } from "../initialState/add-edit-integrator-state";
import toast from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import { IIntegratorState } from "../../models/state/IIntegratorState";
import { initialState as getAllIntegratorState } from "../initialState/get-all-integrator-state";
import {
  IIntegratorVM,
  IIntegratorList,
  IIntegratorListVM,
} from "../../models/response/IIntegratorResponse";
import { parkingLotStatus } from "../../constants/enums/parking-lot-status-enum";
import { isOperationPermittedParkingRoleBase } from "../../helpers/action-permission-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
export class IntegratorStore implements IIntegratorState, ICommonState {
  inProgress: boolean = false;
  error: string = "";

  initialStateValue: IObservableInitialState = {
    success: false,
    error: "",
    inProgress: false,
  };

  integratorList: IIntegratorListVM = getAllIntegratorState;

  integrator: any | undefined = undefined;

  integratorStatusImage: any = undefined;
  integratorThreshold: any = undefined;

  integratorState = { ...this.initialStateValue };

  addUpdateIntegratorState = { ...this.initialStateValue };

  deleteIntegratorState = { ...this.initialStateValue };

  setIntegratorStatusImageState = { ...this.initialStateValue };
  updateIntegratorStatusImageState = { ...this.initialStateValue };
  statusImageByIdState = { ...this.initialStateValue };
  thresholdByIdState = { ...this.initialStateValue };
  integratorByIdState = { ...this.initialStateValue };
  setIntegratorThresholdState = {...this.initialStateValue};
  updateIntegratorStatusState = { ...this.initialStateValue };

  constructor() {
    makeObservable(this, {
      inProgress: observable,
      integrator: observable,
      integratorState: observable,
      integratorList: observable,
      integratorByIdState: observable,
      integratorStatusImage: observable,
      thresholdByIdState: observable,
      statusImageByIdState: observable,
      integratorThreshold: observable,
      updateIntegratorStatusState: observable,
      setIntegratorThresholdState: observable,
      setIntegratorStatusImageState: observable,
      updateIntegratorStatusImageState: observable,
      addUpdateIntegratorState: observable,
      deleteIntegratorState: observable,
      GetIntegratorsListService: action,
      UpdateIntegratorStatusImageService: action,
      GetIntegratorStatusImageById: action,
      GetIntegratorThresholdById: action,
      AddIntegratorService: action,
      UpdateIntegratorService: action,
      GetIntegratorIdService: action,
      SetIntegratorStatusImage: action,
      SetIntegratorThresholdService: action,
      UpdateIntegratorThresholdService: action,
      UpdateIntegratorStatusService: action,
      resetDeleteIntegratorState: action,
      resetAddUpdateIntegratorState: action,
      resetIntegratorStatusImageDetail: action,
      resetSetIntegratorStatusImageState: action,
      resetThresholdUpdateState: action,
      resetThresholdStatusCount: action,
      resetUpdateIntegratoStatusState: action,
      resetUpdateIntegratorStatusImageState: action,
      reset: action,
      resetIntegratorDetail: action,
      allIntegratorList: computed,
      integratorDetail: computed,
      allAvailableParkingStatus: computed,
      statusImageDetails: computed,
      integratorStatusCount: computed,
    });
  }

  /* This function is used to Get All Integrators List data with pagination information by calling an API. */

  GetIntegratorsListService = (
    currentPage: number,
    pagerSize: number,
    tenantId: number
  ) => {
    this.inProgress = true;
    const url =
      URLConstants.GetIntegratorList +
      "?PageNo=" +
      currentPage +
      "&PageSize=" +
      pagerSize +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then(
        (response: IApiResponse<IApiSuccessResponse<IIntegratorListVM>>) => {
          if (response.data.Error) {
            this.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          } else {
            this.integratorList = response.data.Data;
          }
        }
      )
      .catch((error: string) => {
        this.error = error;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  /*  This function is used to Add New Integrator by calling an API. */

  AddIntegratorService = (data: any) => {
    this.addUpdateIntegratorState.inProgress = true;
    let url = URLConstants.AddIntegrator;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateIntegratorState.error = response.data.Message;
        } else {
          this.addUpdateIntegratorState.success = true;
        }
      })
      .catch((error: string) => {
        this.addUpdateIntegratorState.error = error;
      })
      .finally(
        action(() => {
          this.addUpdateIntegratorState.inProgress = false;
        })
      );
  };

  /* This function is used to Updating Existing Integrator Details by calling an API. */

  UpdateIntegratorService = (data: any) => {
    
    this.addUpdateIntegratorState.inProgress = true;
    let url = URLConstants.UpdateIntegrator;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateIntegratorState.error = response.data.Message;
        } else {
          this.addUpdateIntegratorState.success = true;
        }
      })
      .catch((error: string) => {
        this.addUpdateIntegratorState.error = error;
      })
      .finally(
        action(() => {
          this.addUpdateIntegratorState.inProgress = false;
        })
      );
  };

  /*  This function is used to Delete Integrator by calling an API. */

  DeleteIntegratorService = (id: number, tenantId: number) => {
    this.deleteIntegratorState.inProgress = true;
    let url = `${URLConstants.DeleteIntegrator}/${id}?tenantId=${tenantId}`;
    return baseService
      .deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteIntegratorState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.deleteIntegratorState.success = true;
        }
      })
      .catch((error: string) => {
        this.deleteIntegratorState.error = error;
      })
      .finally(
        action(() => {
          this.deleteIntegratorState.inProgress = false;
        })
      );
  };

  /* This function is used to Get Integrator Details by Id by calling an API. */

  GetIntegratorIdService = (id: number, tenantId: number) => {
    this.integratorByIdState.inProgress = true;
    let url = `${URLConstants.GetIntegratorById}/${id}?tenantId=${tenantId}`;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IIntegratorVM>>) => {
        if (response.data.Error) {
          this.integratorByIdState.error = response.data.Message;
        } else {
          let data = response.data.Data;
          this.integrator = data;
        }
      })
      .catch((error: string) => {
        this.integratorByIdState.error = error;
      })
      .finally(
        action(() => {
          this.integratorByIdState.inProgress = false;
        })
      );
  };

  /* This function provides single Integrator details */

  get integratorDetail() {
    if (this.integrator) {
      return {
        Id: this.integrator.Id,
        IntegratorId: this.integrator.IntegratorId,
        IntegratorName: this.integrator.IntegratorName,
        CurrentStatus: this.integrator.CurrentStatus,
        IsClosed: this.integrator.IsClosed,
        Comment: this.integrator.Comment,
        IntegratorParkingLotVMs: this.integrator.IntegratorParkingLotVMs,
        AssignedParkingLot: -1,
      };
    }
    return initialState;
  }

  /**
   * This function is used to Set Parking Lot Status Image by calling an API & sending the required tenant details.
   * @param parkingLotStatus : Parking Lot Status Image Details
   * @returns
   */
  SetIntegratorStatusImage = (integratorStatus: any) => {
    this.setIntegratorStatusImageState.inProgress = true;
    return baseService
      .postRequest(URLConstants.SetIntegratorLinkBaseOut, integratorStatus)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setIntegratorStatusImageState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.setIntegratorStatusImageState.success = true;
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.setIntegratorStatusImageState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to update existing Status Image Data by calling an API & sending updated Parking Lot details.
   * @param parkingLotStatus : ParkingLot Status Details
   * @returns
   */
  UpdateIntegratorStatusImageService = (statusImage: any) => {
    this.updateIntegratorStatusImageState.inProgress = true;
    return baseService
      .putRequest(URLConstants.UpdateIntegratorStatusImage, statusImage)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updateIntegratorStatusImageState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else this.updateIntegratorStatusImageState.success = true;
      })
      .catch((err: string) => {
        if (err.includes(":")) {
          let errorMess = err.split(":");
          toast.error(errorMess[0] + " : " + formatMessage(errorMess[1]));
        } else {
          toast.error(formatMessage(err));
        }
      })
      .finally(
        action(() => {
          this.updateIntegratorStatusImageState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Get Parking Lot Status Image By Id Details by calling an API.
   * @param imageId : Status Identifier
   * @returns
   */
  GetIntegratorStatusImageById = (Id: number, tenantId: number) => {
    this.statusImageByIdState.inProgress = true;
    const url =
      URLConstants.GetIntegratorStatusImageById +
      "/Id" +
      "?Id=" +
      Id +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.statusImageByIdState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.integratorStatusImage = response.data.Data;
          this.statusImageByIdState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.statusImageByIdState.inProgress = false;
        })
      );
  };

  /**
   * This function is used to Get Parking Lot Status Image By Id Details by calling an API.
   * @param imageId : Status Identifier
   * @returns
   */
  GetIntegratorThresholdById = (integratorId: number, tenantId: number) => {
    this.thresholdByIdState.inProgress = true;
    const url =
      URLConstants.GetIntegratorThresholdById +
      "?integratorid=" +
      integratorId +
      "&tenantId=" +
      tenantId;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
        if (response.data.Error) {
          this.thresholdByIdState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        } else {
          this.integratorThreshold = response.data.Data;
          this.thresholdByIdState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.thresholdByIdState.inProgress = false;
        })
      );
  };

  get integratorStatusCount(): any {
    let obj = {
      ThresholdId: 0,
      TotalParkingCount: 0,
      AvailUpto: 0,
      FullAfter: 0,
    };
    if (
      this.integratorThreshold &&
      this.integratorThreshold?.IntegratorThresholdVMs?.length > 0
    ) {
      obj.ThresholdId =
        this.integratorThreshold?.IntegratorThresholdVMs[0]?.ThresholdId;
      obj.AvailUpto =
        this.integratorThreshold?.IntegratorThresholdVMs[0]?.AvailUpto;
      obj.FullAfter =
        this.integratorThreshold?.IntegratorThresholdVMs[0]?.FullAfter;
      }
      obj.TotalParkingCount = this.integratorThreshold?.TotalCount;
    return obj;
  }

  /* This function is used to map userList to all Integrator List suitable for Grid Component. */

  get allIntegratorList(): IIntegratorList[] {
    if (
      this.integratorList?.integrator &&
      this.integratorList.integrator?.length > 0
    )
      return this.integratorList.integrator.map((integrator: any) => {
        let isDelete = true;
        let isEdit = true;
        let isImage = true;
        let isLinkbaseOut = true;
        let isThreshold = true;
        let isIntegratorOpenClosed = isOperationPermittedParkingRoleBase(
          ModuleType.Integrator,
          "Add"
        );
        return {
          Id: integrator.Id,
          IntegratorId: integrator.IntegratorId,
          IntegratorName: integrator.IntegratorName,
          CurrentStatus: integrator.CurrentStatus,
          IsClosed: integrator.IsClosed,
          Comment: integrator.Comment,
          IntegratorParkingLotVMs: integrator.IntegratorParkingLotVMs,
          isIntegratorOpenClosed: isIntegratorOpenClosed,
          isEdit: isEdit,
          isDelete: isDelete,
          isImage: isImage,
          isLinkbaseOut: isLinkbaseOut,
          isThreshold: isThreshold,
        };
      });
    return [];
  }

  get allAvailableParkingStatus(): IOption[] {
    const statusOptions: IOption[] = [
      {
        id: -1,
        value: "please_select",
        disabled: false,
      },
    ];

    const statusKeys = Object.keys(parkingLotStatus).filter((key) =>
      isNaN(Number(key))
    );
    statusKeys.forEach((key: string, index: number) => {
      const value = parkingLotStatus[key as keyof typeof parkingLotStatus];
      statusOptions.push({
        id: index,
        value: formatMessage(parkingLotStatus[value]),
        disabled: false,
      });
    });
    return statusOptions;
  }

  /**
   * This function provides initail values to the Add  Device Form.
   * @returns Initial Device Details
   */
  get statusImageDetails(): any {
    let orgId =
      localStorage.getItem("hips_access_org_id") !== null
        ? localStorage.getItem("hips_access_org_id")
        : "null";
    let obj = {
      AvailableImage: -1,
      CrowdedImage: -1,
      ClosedImage: -1,
      FullImage: -1,
      StatusId: -1,
      FolderId: -1,
      IsUpdate: false,
      Width: 500,
      Height: 500,
      StatusDataAdd: [],
      StatusDataUpdate: [],
    };
    if (this.integratorStatusImage && this.integratorStatusImage.length > 0) {
      let data: any = [];
      let combineObjs = {
        AvailableImageSource: "",
        CrowdedImageSource: "",
        FullImageSource: "",
        ClosedImageSource: "",
        AvailPkey: -1,
        CrowdedPkey: -1,
        FullPkey: -1,
        ClosedPkey: -1,
        FolderId: -1,
        Height: 0,
        Width: 0
      }
      this.integratorStatusImage?.map((status: any, index: number) => {
        if(status?.Status == 0){
          combineObjs.AvailPkey = status?.ImageId;
          combineObjs.AvailableImageSource = status?.ImageSource;
          combineObjs.FolderId = status?.FolderId;
          combineObjs.Height = status?.Height;
          combineObjs.Width = status?.Width;
        }
        else if(status?.Status == 1){
          combineObjs.CrowdedPkey = status?.ImageId;
          combineObjs.CrowdedImageSource = status?.ImageSource;
        }
        else if(status?.Status == 2){
          combineObjs.FullPkey = status?.ImageId;
          combineObjs.FullImageSource = status?.ImageSource;
        }
        else {
          combineObjs.ClosedPkey = status?.ImageId;
          combineObjs.ClosedImageSource = status?.ImageSource;
        }

      });
      
      data.push(combineObjs);
      obj.StatusDataUpdate = data;
      obj.IsUpdate = true;
    }
    return obj;
  }

  SetIntegratorThresholdService = (data: any) => {
    this.setIntegratorThresholdState.inProgress = true;
    let url = URLConstants.SetIntegratorThreshold;
    return baseService
      .postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setIntegratorThresholdState.error = response.data.Message;
        } else this.setIntegratorThresholdState.success = true;
      })
      .catch((err: string) => {
        this.setIntegratorThresholdState.error = err;
      })
      .finally(
        action(() => {
          this.setIntegratorThresholdState.inProgress = false;
        })
      );
  };

    /**
   * This function is used to Updating Existing Parking Lot Details by calling an API.
   * @param id : The Parking Lot Identifier
   * @param data
   * @returns
   */
    UpdateIntegratorStatusService = (data: any) => {
      this.updateIntegratorStatusState.inProgress = true;
      let url = URLConstants.UpdateIntegratorStatus;
      return baseService
        .putRequest(url, data)
        .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
          if (response.data.Error) {
            this.updateIntegratorStatusState.error = response.data.Message;
          } else this.updateIntegratorStatusState.success = true;
        })
        .catch((err: string) => {
          this.updateIntegratorStatusState.error = err;
        })
        .finally(
          action(() => {
            this.updateIntegratorStatusState.inProgress = false;
          })
        );
    };

  UpdateIntegratorThresholdService = (data: any) => {
    this.setIntegratorThresholdState.inProgress = true;
    let url = URLConstants.UpdateIntegratorThreshold;
    return baseService
      .putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.setIntegratorThresholdState.error = response.data.Message;
        } else this.setIntegratorThresholdState.success = true;
      })
      .catch((err: string) => {
        this.setIntegratorThresholdState.error = err;
      })
      .finally(
        action(() => {
          this.setIntegratorThresholdState.inProgress = false;
        })
      );
  };


  resetIntegratorStatusImageDetail = () => {
    this.integratorStatusImage = undefined;
    this.statusImageByIdState = { ...this.initialStateValue };
  };

  resetSetIntegratorStatusImageState = () => {
    this.setIntegratorStatusImageState = { ...this.initialStateValue };
  };

  resetUpdateIntegratorStatusImageState = () => {
    this.updateIntegratorStatusImageState = { ...this.initialStateValue };
  };

  resetThresholdStatusCount = () => {
    this.integratorThreshold = undefined;
    this.thresholdByIdState = {...this.initialStateValue};
  }

  resetThresholdUpdateState = () => {
    this.setIntegratorThresholdState = {...this.initialStateValue};
  }

  resetDeleteIntegratorState = () => {
    this.deleteIntegratorState = { ...this.initialStateValue };
  };

  resetUpdateIntegratoStatusState = () => {
    this.updateIntegratorStatusState = { ...this.initialStateValue };
  };

  /* This function is used to reset Integrator detail by id observables to their initial values.
   */

  resetIntegratorDetail = () => {
    this.integrator = undefined;
    this.integratorState = { ...this.initialStateValue };
  };

  /* This function is used to reset all Add Integrator observables to their initial values.
   */

  reset = () => {
    this.error = "";
    this.inProgress = false;
    this.addUpdateIntegratorState.success = false;
    this.addUpdateIntegratorState.inProgress = false;
    this.deleteIntegratorState = { ...this.initialStateValue };
    this.integratorState = { ...this.initialStateValue };
  };

  resetAddUpdateIntegratorState = () => {
    this.addUpdateIntegratorState.inProgress = false;
    this.addUpdateIntegratorState.success = false;
    this.addUpdateIntegratorState.error = "";
  };
}

export default new IntegratorStore();
