/**
 * This functional component "Add Media" provides form to add new Media or update existing Media .
 * Created Date:23-April-2024
 */
import "./edit-image-media-form.css";
import { FormattedMessage } from "react-intl";
import { Formik, Field, Form } from "formik";
import { observer } from "mobx-react";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import LoaderButton from "../../../../shared-components/button/loader-button";
import Label from "../../../../shared-components/label/label";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useStore } from "../../../../contexts/store-provider";
import {
  AddImageMediaValidateSchema,
  AddMediaHeightWidthalidateSchema,
  EmptyValidateSchema,
  UpdateImageMediaValidateSchema,
  UpdateImagePricingMediaValidateSchema,
  UploadImageMediaValidateSchema,
} from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormImageFileInput from "../../../../shared-components/FormikFormHandlers/FormikFormImageFileInput";
import { IFolderOption } from "../../../../models/ICommon";
import folderCategoryTypeEnum from "../../../../constants/enums/folder-category-type-enum";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  selectedFolderType: number;
  allFolders: IFolderOption[];
  onModalClosed: () => void;
  initialValues: any;
  isLoading: boolean;
}

const AddUpdateImageMediaForm2 = (props: IProps) => {
  const { preferencesStore } = useStore();
  const { getMediaAccess } = preferencesStore;
  const { initialValues, submitHandler, isLoading, onModalClosed } = props;

  const [data, setData] = useState({ ...initialValues });
  // const [showHidePassword, setShowHidePassword] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setData({ ...initialValues });
  }, [initialValues]);

  const handleNextStep = (newData: any, final: boolean = false) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    if (final) {
      let tempValues: any = { ...newData };
      submitHandler(tempValues);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const getFolderCategoryById = (): any => {
    const item = props.allFolders.find(
      (item: any) => item.id === getMediaAccess.folderId
    );
    return item ? item.category : false;
  };

  const StepOne = (props1: any) => {
    const handleSubmit = (values: any) => {
      // if(props.Id > 0){
      //     props.submitHandler(values);
      //     // props1.next(values, props.Id === -1 ? false : true);
      // }else{

      props1.next({ ...values });
      // }
    };
    return (
      <Formik
        initialValues={props1.data}
        onSubmit={handleSubmit}
        validationSchema={
          props.selectedFolderType == 3 ||
          props.selectedFolderType == 4 ||
          props.selectedFolderType == 5
            ? UpdateImageMediaValidateSchema
            : props.selectedFolderType == 1
            ? UpdateImagePricingMediaValidateSchema
            : EmptyValidateSchema

          // props.selectedFolderType == folderCategoryTypeEnum.PriceImages
          //   ? props.id == -1
          //     ? AddImagePricingMediaValidateSchema
          //     : UpdateImagePricingMediaValidateSchema
          //   : props.id == -1
          //   ? AddImageMediaValidateSchema
          //   : UpdateImageMediaValidateSchema
        }
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, isValid }) => {
          return (
            <Form id="formBody">
              {props.id === -1 ||
                (props.id > 0 && props.selectedFolderType == 1 && (
                  <div className="pt-3">
                    <div className="progress step-progress">
                      <div className="progress-bar" role="progressbar"></div>
                    </div>
                    <div className="step-container d-flex justify-content-between">
                      <div className="step-circle active">
                        1{" "}
                        <span className="step-detail">
                          <FormattedMessage id="image_details" />
                        </span>
                      </div>
                      <div className="step-circle">
                        2{" "}
                        <span className="step-detail">
                          <FormattedMessage id="upload_image" />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="form-group formSet">
                    <Label
                      className="label-style"
                      required={true}
                      label="image_name"
                    />
                    <Field
                      data-testid="imageName"
                      className="form-control"
                      name="ImageName"
                      placeholder="image_name"
                      type="text"
                      disabled={props.id > 0 ? true : false}
                      validcharacter={["@", "#", "$", "-", "_", "."]}
                      invalidcharacter={[" "]}
                      as={FormikFormInput}
                    />
                  </div>
                </div>

                {props.selectedFolderType == 1 ? (
                  <div className="col-md-12 col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={true}
                        label="pricing_value"
                      />
                      <Field
                        data-testid="pricing_value"
                        className="form-control"
                        name="PricingValue"
                        placeholder="pricing_value"
                        disabled={props.id > 0}
                        type="text"
                        as={FormikFormInput}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {(props.selectedFolderType == 3 ||
                  props.selectedFolderType == 4 ||
                  props.selectedFolderType == 5) && (
                  <div className="row pe-0">
                    <div className="col-md-6 col-6">
                      <div className="form-group formSet">
                        <Label
                          className="label-style"
                          required={true}
                          label="media_height"
                        />
                        <Field
                          data-testid="media_height"
                          className="form-control"
                          name="Height"
                          placeholder="media_height"
                          type="number"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-6">
                      <div className="form-group formSet">
                        <Label
                          className="label-style"
                          required={true}
                          label="media_width"
                        />
                        <Field
                          data-testid="media_height"
                          className="form-control"
                          name="Width"
                          placeholder="media_width"
                          type="number"
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {props.selectedFolderType !== 1 && (
                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label
                        className="label-style"
                        required={false}
                        label="comment"
                      />
                      <Field
                        rows={props.id == -1 ? 3 : 2}
                        id="Comment"
                        className="form-control"
                        name="Comment"
                        placeholder="comment"
                        type="text"
                        as={FormikFormTextArea}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="pb-3 mt-3 d-flex justify-content-center">
                {props.id > 0 && props.selectedFolderType !== 1 ? (
                  <LoaderButton
                    type={isValid ? "submit" : "button"}
                    id="SaveTenant"
                    onClick={
                      isValid
                        ? () => {
                            props1.next(values, true);
                          }
                        : undefined
                    }
                    isLoading={props.isLoading}
                    text="submit"
                    className="ms-2"
                  />
                ) : (
                  <LoaderButton
                    type={"submit"}
                    id="SaveTenant"
                    isLoading={props.isLoading}
                    text="next"
                  />
                )}
                {/* {
                  props.Id > 0 &&
                  <LoaderButton
                    type={isValid ? "submit" : "button"}
                    id="SaveTenant"
                    onClick={isValid ? ()=>{props1.next(values, true);} : undefined}
                    isLoading={props.isLoading}
                    text="Update"
                    className="ml-3"
                  />
                } */}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const StepTwo = (props2: any) => {
    const [fileData, setFileData] = useState<object>([]);
    const [imageBase64, setImageBase64] = useState<any>();
    const [imageExtension, setImageExtension] = useState<any>();
    const handleSubmit = (values: any) => {
      props.submitHandler({
        ...values,
        File: fileData,
        Extension: imageExtension,
        Base64: imageBase64,
        FolderType: props.selectedFolderType,
      });
      // props2.next(values, props.Id === -1 ? false : true);
    };
    return (
      <Formik
        initialValues={props2.data}
        onSubmit={handleSubmit}
        validationSchema={UploadImageMediaValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form id="formBody">
              <div className="pt-3">
                <div className="progress px-1 step-progress">
                  <div
                    className={`progress-bar ${
                      props.id === -1 ? "w-50" : "w-100"
                    }`}
                    role="progressbar"
                  ></div>
                </div>
                <div className="step-container d-flex justify-content-between">
                  <div className="step-circle completed">
                    1{" "}
                    <span className="step-detail">
                      <FormattedMessage id="image_details" />
                    </span>
                  </div>
                  <div className="step-circle active">
                    2{" "}
                    <span className="step-detail">
                      <FormattedMessage id="upload_image" />
                    </span>
                  </div>
                </div>
              </div>
              {(props.id > 0 && props.selectedFolderType == 1) &&
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-1">
                    <Label
                      className="label-style"
                      required={true}
                      label="current_saved_image"
                    />
                    <div className="image_box">
                      <img
                        src={props.initialValues.Base64}
                        className="img-fluid img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
              </div>
              }
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-1">
                    <Label
                      className="label-style"
                      required={true}
                      label="image_file"
                    />
                    <Field
                      className="form-control"
                      id="image_file"
                      name="File"
                      setFile={setFileData}
                      setBase64={setImageBase64}
                      setExtension={setImageExtension}
                      accept=".jpg, .jpeg, .png, .svg"
                      as={FormikFormImageFileInput}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-3 mt-4 d-flex justify-content-center">
                <Button
                  data-testid="back"
                  variant="light-secondary"
                  onClick={() => {
                    props2.prev(values);
                  }}
                  disabled={props.isLoading}
                  className="mr-3"
                >
                  <FormattedMessage id="back" />
                </Button>

                <LoaderButton
                  type="submit"
                  id="SaveTenant"
                  className="ms-2"
                  isLoading={props.isLoading}
                  text="submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  const steps = [
    <StepOne
      next={handleNextStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
    <StepTwo
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      Id={props.id}
      isLoading={isLoading}
    />,
  ];

  return (
    <div>
      {isLoading && <FormLoader loading={isLoading} />}
      {steps[currentStep]}
    </div>
  );
};
export default observer(AddUpdateImageMediaForm2);
