import React from "react";
import { Redirect, Switch, withRouter } from "react-router-dom";
import LoginLayoutRoute from "../layout/login-layout-route";
import LayoutRoute from "../layout/layout-route";
import { observer } from "mobx-react";
import RoutesConstants from "../../constants/routes.constant";
import Login from "../../modules/auth/login";
import HucksterUser from "../../modules/user/huckster-user";
import SystemUser from "../../modules/user/system-user";
import Tenant from "../../modules/tenant/tenant";
import { useStore } from "../../contexts/store-provider";
import userTypeEnum from "../../constants/enums/user-type-enum";
import Branch from "../../modules/branch/branch";
import Media from "../../modules/media/media";
import ParkingLot from "../../modules/parking-lot/parking-lot";
import Integrator from "../../modules/integrator/integrator";
import Camera from "../../modules/camera/camera";
import Folder from "../../modules/media/folder";
import AccountActivation from "../../modules/account-activation/account-activation";
import WeekdayScheduler from "../../modules/price-scheduler/weekday-scheduler";
import UserLogs from "../../modules/logs/user-logs/user-logs";
import systemLogs from "../../modules/logs/system-logs/system-logs";
import { findRouteAccess, redirectToDefaultRoute } from "../../helpers/route-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import LinkbaseOut from "../../modules/linkbase-out/linkbase-out";
import soracomAuth from "../../modules/soracom-auth/soracom-auth";
import integrator from "../../modules/integrator/integrator";
import integratorLinkbaseOut from "../../modules/integrator-linkbase-out/integrator-linkbase-out";
import resetPassword from "../../modules/reset-password/components/reset-password";
import forgetPassword from "../../modules/forget-password/components/forget-password";

const Routes = () => {
  const {preferencesStore} = useStore();
  const {getParkingLotCameraAccess, getMediaAccess, getPriceSchedulerAccess, getLinkBaseOutAccess} = preferencesStore;
    return (
        <React.Fragment>
            <Switch>
                <LoginLayoutRoute path={RoutesConstants.Login} component={Login} />
                <LayoutRoute path={RoutesConstants.AccountActivation} component={AccountActivation} />
                <LayoutRoute path={RoutesConstants.ResetPassword} component={resetPassword} />
                <LayoutRoute path={RoutesConstants.ForgotPassword} component={forgetPassword} />
                {findRouteAccess(ModuleType.HucksterUserManagement) && <LayoutRoute path={RoutesConstants.HucksterUser} component={HucksterUser} />}
                {findRouteAccess(ModuleType.CompanyUserManagement) && <LayoutRoute path={RoutesConstants.SystemUser} component={SystemUser} />}
                {findRouteAccess(ModuleType.TenantManagement) && <LayoutRoute path={RoutesConstants.TenantManagement} component={Tenant} />}
                {findRouteAccess(ModuleType.BranchManagement) && <LayoutRoute path={RoutesConstants.BranchManagement} component={Branch} />}
                {findRouteAccess(ModuleType.MediaManagement) && <LayoutRoute path={RoutesConstants.FoldersManagement} component={Folder} />}
                {findRouteAccess(ModuleType.ParkingManagementList) && <LayoutRoute path={RoutesConstants.ParkingLotManagement} component={ParkingLot} />}
                {findRouteAccess(ModuleType.UserLog) && <LayoutRoute path={RoutesConstants.UserLogs} component={UserLogs} />}
                {findRouteAccess(ModuleType.SystemLog) && <LayoutRoute path={RoutesConstants.SystemLogs} component={systemLogs} />}
                {findRouteAccess(ModuleType.SoracomAuth) && <LayoutRoute path={RoutesConstants.SoracomAuth} component={soracomAuth} />}
                {findRouteAccess(ModuleType.Integrator) && <LayoutRoute path={RoutesConstants.IntegratorManagement} component={integrator} />}
                {findRouteAccess(ModuleType.Integrator) && <LayoutRoute path={RoutesConstants.IntegratorLinkbaseOut} component={integratorLinkbaseOut} />}
                {(findRouteAccess(ModuleType.ParkingManagementList) && getLinkBaseOutAccess.isLinkBaseOutAccess == true) && <LayoutRoute path={RoutesConstants.LinkbaseOut} component={LinkbaseOut} />}
                {(findRouteAccess(ModuleType.PriceSchedular) && getPriceSchedulerAccess.isAccessPriceSchedulerActive == true) && <LayoutRoute path={RoutesConstants.PriceScheduler} component={WeekdayScheduler} />}
                {(findRouteAccess(ModuleType.MediaManagement) && getMediaAccess.isAccessMediaManagementActive == true) && <LayoutRoute path={RoutesConstants.MediaManagement} component={Media} />}
                {(findRouteAccess(ModuleType.ParkingManagementList) && getParkingLotCameraAccess.isAccessCameraActive == true) && <LayoutRoute path={RoutesConstants.CameraManagement} component={Camera} />}
                <Redirect to={redirectToDefaultRoute()}/>
            </Switch>
        </React.Fragment>
  );
};

export default withRouter(observer(Routes));
