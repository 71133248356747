/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in providing paths for API Route URL for every modules.
      Developer: Aashish Singh, Created Date: 29-March-2023
</summary>
*/

const URLConstants: any = {
    Authenticate: '/Account/Login',
    RefreshToken: 'Account/RefreshToken',
    Logout: '/Account/Logout',
    ActivateAccount: '/Account/Activate',
    ResendLink: 'Account/Activate/Resend/Link',
    PasswordResetLink: 'Account/User/Email/Verification',
    ResetPassword: 'Account/Update/User/Password',
    ChangePassword: 'Account/Update/Password',
    AuthorizeAccount: 'Account/Authorize',
    AddHucksterUser: '/User',
    GetHucksterUserList: '/User/List',
    GetSystemUserList: '/User/TenantId',
    GetUserById: '/User',  
    DeleteUser: '/User', 
    UpdateUser: '/User', 
    TenantList: '/Tenant',
    GetTenantById: '/Tenant',
    AddTenant: '/Tenant',
    UpdateTenant: '/Tenant',
    DeleteTenant: '/Tenant',
    AllTenants: '/Tenant',
    Branch:'/BranchManagement',
    AddUser: '/v1/User/AddUser', 
    AllBranches: '/BranchManagement/All',

    GetParkingLotById: '/ParkingLots',
    ParkingLotList: '/ParkingLots/List',
    AddParkingLot: '/ParkingLots',
    ParkingLot: '/ParkingLots',
    DeleteParkingLot: 'ParkingLots',
    AllParkingLot: 'ParkingLots/All',
    UpdateParkingLot: 'ParkingLots/CurrentStatus',
    SetParkingLotThreshold: 'ParkingLots/Threshold',
    GetParkingLotFilterList: 'ParkingLots/Filter',
    GetParkingLotStatus: 'ParkingLots/ParkingLotStatus',

    AddMediaFolder: '/MediaManagement/Folder/Add',
    UpdateMediaFolder: '/MediaManagement/Folder/Update',
    DeleteMediaFolder: '/MediaManagement/Folder/Delete',
    GetMediaFolderList: '/MediaManagement/Folder/List',
    GetAllMediaFolders: '/MediaManagement/Folder/All',
    GetMediaFolderById: '/MediaManagement/Folder/Id',
    GetFolderStyleById: 'MediaManagement/Folder/MediaCategory/Type',

    AddCamera: '/Camera',
    UpdateCamera: '/Camera',
    DeleteCamera: '/Camera',
    GetCameraList: '/Camera/List',
    GetCameraById: '/Camera',
    GetAllCamera: '/Camera/All',
    GetAllCameraSlots: '/Camera/All/Slots',
    GetCameraSlotCountById: 'Camera/PlId/Slots/Count',
    GetCameraSlotById: 'Camera/Slots/CameraId',


    GetMediaList: '/MediaManagement/Media/List',

    AddImage: '/MediaManagement/Folder/Images/Add',
    AddPricingImage : 'MediaManagement/Folder/PriceImages/Add',
    UpdateImage: '/MediaManagement/Folder/Images/Update',
    UploadImage: '/MediaManagement/Folder/Images/Upload',
    GetAllImagesFolderId: '/MediaManagement/Folder/Images/FolderId',
    GetAllImages: '/MediaManagement/Folder/Images/All',
    GetImageById: '/MediaManagement/Folder/Images/Id',
    DeleteImage: '/MediaManagement/Folder/Images/Delete',

    AddVideo: '/MediaManagement/Folder/Videos/Add',
    UpdateVideo: '/MediaManagement/Folder/Videos/Update',
    UploadVideo: '/MediaManagement/Folder/Videos/Upload',
    GetVideoById: '/MediaManagement/Folder/Videos/Id',
    DeleteVideo: '/MediaManagement/Folder/Videos/Delete',

    AddPriceScheduler: 'PriceScheduler',
    UpdatePriceScheduler: 'PriceScheduler',
    GetPriceSchedulerList: 'PriceScheduler/List',
    GetPriceSchedulerById: 'PriceScheduler',
    GetAllPriceScheduler: 'PriceScheduler/All',
    DeletePriceScheduler: 'PriceScheduler',
    GetPriceSchedulerMap: 'PriceScheduler/ImageDigitMapping/All',
    SetPriceSchedulerMap: 'PriceScheduler/ImageMapping',
    UpdatePriceSchedulerMap: 'PriceScheduler/ImageDigitMapping',
    GetCurrentSchedule: 'PriceScheduler/CurrentSchedule/Id',
    SignBoardSchedule: 'PriceScheduler/SignBoardImage/PlId',
    AddSignBoardSchedule:'PriceScheduler/SignBoardImage',
    UpdateSignBoardSchedule:'PriceScheduler/SignBoardImage',

    GetSpecialSchedulerList: 'PriceScheduler/SpecialSchedule/List',
    AddSpecialScheduler: 'PriceScheduler/SpecialSchedule',
    UpdateSpecialScheduler: 'PriceScheduler/SpecialSchedule',
    DeleteSpecialScheduler: 'PriceScheduler/SpecialSchedule',
    GetSpecialSchedulerById: 'PriceScheduler/SpecialSchedule/Id',
    GetAllSpecialScheduler: 'PriceScheduler/SpecialSchedule/All',
    AddScheduleCSV: 'PriceScheduler/CSV',

    SetParkingLotStatusImage: 'ParkingLots/ImageStatus',
    UpdateParkingLotStatusImage: 'ParkingLots/ImageStatus',
    GetParkingLotStatusImageById: 'ParkingLots/ImageStatusDetail/All',
    AllCategoryParkingLot: 'ParkingLots/Category/All',
    
    UserLogsList: 'Logs/User/List',
    UserLogsFilterList: 'Logs/Filter',
    SystemLogsFilterList: 'Logs/Filter/SystemLog',
    SystemLogsList: 'Logs/System/List',
    Version:'/v1/Version',

    GetAllLinkbaseInRelay: 'LinkBase/AllLinkBaseInRelay',
    GetLinkBaseInRelayByPlId: 'LinkBase/AllLinkBaseIn/PlId',
    AddLinkBaseIn: 'LinkBase/LinkBaseIn',
    UpdateLinkBaseIn: 'LinkBase/LinkBaseIn',
    AddLinkBaseOut: 'LinkBase/LinkBaseOut',
    UpdateLinkBaseOut: 'LinkBase/LinkBaseOut',
    GetLinkBaseInByRelayType: 'LinkBase/AllLinkBaseInRelay/Type',
    GetLinkBaseOutRelayType: 'LinkBase/AllLinkBaseOutRelay/Type',
    GetParkingLotAllLinkbaseOut: 'LinkBase/AllLinkBaseOut/PlId',
    GetLinkbaseOutBySimId: 'LinkBase/AllLinkBaseOut/LinkBaseOutSimId',
    DeleteLinkbaseOut: 'LinkBase/LinkBaseOut',
    DeleteLinkbaseIn: 'LinkBase/LinkBaseIn',
    
    AddLinkbaseInThreshold: 'LinkBase/LinkBaseInThreshold',
    UpdateLinkbaseInThreshold: 'LinkBase/LinkBaseInThreshold',
    GetAllDefaultThreshold:'LinkBase/AllLinkBaseInThresholdDefault',
    GetThresholdByPlId: 'LinkBase/LinkBaseInThreshold',

    AddSoracom: 'LinkBase/Soracom',
    UpdateSoracom: 'LinkBase/Soracom',
    DeleteSoracom: 'LinkBase/Soracom',
    GetAllSoracom: 'LinkBase/Soracom/All',
    GetSoracomById: 'LinkBase/Soracom',
    GetSoracomList: 'LinkBase/Soracom/List',

    GetIntegratorList: 'Integrator/List',
    AddIntegrator: 'Integrator',
    UpdateIntegrator: 'Integrator',
    GetIntegratorById: 'Integrator',
    AddIntegratorLinkBaseOut: 'Integrator/LinkBaseOut',
    UpdateIntegratorLinkBaseOut: 'Integrator/LinkBaseOut',
    SetIntegratorLinkBaseOut: 'Integrator/ImageStatus',
    UpdateIntegratorStatusImage: 'Integrator/ImageStatus',
    GetIntegratorLinkbaseOutBySimId: 'Integrator/LinkBaseOutSimId',
    GetIntegratorStatusImageById: 'Integrator/ImageStatus',
    GetIntegratorLinkbaseOutList: 'Integrator/IntegratorId',
    DeleteIntegratorLinkbaseOut: 'Integrator/LinkBaseOut',
    DeleteIntegrator: 'Integrator',
    GetIntegratorThresholdById: 'Integrator/Threshold/IntegratorId',
    SetIntegratorThreshold: 'Integrator/Threshold',
    UpdateIntegratorThreshold: 'Integrator/Threshold',
    UpdateIntegratorStatus: 'Integrator/CurrentStatus',

    UpdatePricingImage: 'MediaManagement/Folder/PriceImages/Update'
}

export default URLConstants;